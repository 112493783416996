<template>
  <f7-app v-bind="f7params">
    <template v-if="isCoreAppLoaded">
      <!-- Views/Tabs container -->
      <template v-if="isUserLoggedIn">
        <f7-views tabs class="safe-areas">
          <!-- Tabbar for switching views-tabs -->
          <f7-toolbar tabbar icons bottom>
            <f7-link
              tab-link="#view-home"
              tab-link-active
              icon-ios="f7:house_fill"
              icon-md="material:home"
              text="Domov"
            ></f7-link>
            <f7-link
              tab-link="#view-profile"
              icon-ios="f7:person_crop_circle"
              icon-md="material:account_circle"
              text="Profil"
            ></f7-link>
          </f7-toolbar>

          <f7-view id="view-home" main tab tab-active url="/"></f7-view>
          <f7-view id="view-profile" name="profile" tab url="/profile/"></f7-view>
        </f7-views>

        <!-- Popup -->
        <ParkingReservationForm class="parking-reservation-form" />
      </template>

      <f7-login-screen :opened="!isUserLoggedIn">
        <f7-view>
          <LoginPage />
        </f7-view>
      </f7-login-screen>
    </template>
  </f7-app>
</template>

<script setup lang="ts">
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { Browser } from '@capacitor/browser'
import { CapacitorCookies } from '@capacitor/core'
import { Preferences } from '@capacitor/preferences'
import { SplashScreen } from '@capacitor/splash-screen'
import { inject } from '@vercel/analytics'
import { getDevice } from 'framework7'
import { f7, f7ready, useStore } from 'framework7-vue'
import { Settings } from 'luxon'
import { computed, onMounted, ref, watch } from 'vue'

import { checkTokenValidity, setActiveUserIfAny } from '../assets/composables/auth'
import { initNetworkListeners, initPushNotifications } from '../assets/composables/utils'

import capacitorApp from '../js/capacitor-app.js'
import routes from '../js/routes'
import store from '../js/store'

import LoginPage from './login.vue'
import ParkingReservationForm from './parking/reservationForm/index.vue'

Settings.defaultZone = 'Europe/Bratislava'
Settings.defaultLocale = 'sk-SK'

const device = getDevice()
const isCoreAppLoaded = ref<boolean | undefined>(undefined)
const isUserLoggedIn = computed<boolean>(() => useStore('isUserLoggedIn').value)

const f7params = {
  name: 'Pelikánec', // App name
  theme: 'auto', // Automatic theme detection
  colors: {
    primary: '#EC8023',
    secondary: '#42B769',
  },
  dialog: {
    buttonCancel: 'Zrušiť',
  },
  // App store
  store: store,
  // App routes
  routes: routes,

  // Register service worker (only on production build)
  serviceWorker:
    process.env.NODE_ENV === 'production'
      ? {
          path: '/service-worker.js',
        }
      : {},
  // Input settings
  input: {
    scrollIntoViewOnFocus: device.capacitor,
    scrollIntoViewCentered: device.capacitor,
  },
  // Capacitor Statusbar settings
  statusbar: {
    iosOverlaysWebView: true,
    androidOverlaysWebView: false,
  },
}

const checkForUpdate = async () => {
  const latestVersion = store.getters?.defaultSettings?.value?.latestFeBreakingChangeVersion || ''
  const currentVersion = __APP_VERSION__

  const isAppDeprecated = () => {
    const v1 = currentVersion.split('.').map(Number)
    const v2 = latestVersion.split('.').map(Number)

    for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
      const num1 = v1[i] || 0
      const num2 = v2[i] || 0

      if (num1 > num2) {
        return 1
      }
      if (num1 < num2) {
        return -1
      }
    }

    return 0
  }

  if (latestVersion && isAppDeprecated() === -1) {
    f7.dialog
      .create({
        title: 'Je k dispozícii nová verzia appky',
        text: `Medzičasom sme vydali majoritnú aktualizáciu systému.
        Táto aktualizácia zmenila základné štruktúry appky na pozadí. Z dôvodu zachovania správnej funkčnosti aplikácie je ju nutné aktualizovať.
        Choď do svojho obchodu s aplikáciami a vykonaj aktualizáciu. Appka bude do tej doby zablokovaná.`,
      })
      .open()
  }
}

const initApp = async () => {
  initNetworkListeners()
  initPushNotifications()
  isCoreAppLoaded.value = false

  const urlParams = new URLSearchParams(window?.location?.search)
  const userId = urlParams.get('userId')
  const secret = urlParams.get('secret')

  if (userId && secret) {
    await Promise.all([
      Preferences.set({
        key: 'oauth-userId',
        value: userId,
      }),
      Preferences.set({
        key: 'oauth-secret',
        value: secret,
      }),
      CapacitorCookies.setCookie({
        key: 'oauth-userId',
        value: userId,
      }),
      CapacitorCookies.setCookie({
        key: 'oauth-secret',
        value: secret,
      }),
    ])
  }

  if (!device.cordova) {
    inject()
  }

  try {
    await setActiveUserIfAny()
    await checkForUpdate()
  } finally {
    isCoreAppLoaded.value = true
    SplashScreen.hide()
  }
}

onMounted(() => {
  f7ready(async (f7) => {
    // Init capacitor APIs (see capacitor-app.js)
    if (device.capacitor) {
      capacitorApp.init(f7)

      App.addListener('appUrlOpen', async (data: URLOpenListenerEvent) => {
        const url = new URL(data.url)
        const userId = url.searchParams.get('userId')
        const secret = url.searchParams.get('secret')

        await Promise.all([
          Preferences.set({
            key: 'oauth-userId',
            value: userId,
          }),
          Preferences.set({
            key: 'oauth-secret',
            value: secret,
          }),
          CapacitorCookies.setCookie({
            key: 'oauth-userId',
            value: userId,
          }),
          CapacitorCookies.setCookie({
            key: 'oauth-secret',
            value: secret,
          }),
        ])

        await Browser.close()
        await initApp()
      })

      App.addListener('backButton', ({ canGoBack }) => {
        if (!canGoBack && !isUserLoggedIn.value) {
          App.exitApp()
        } else {
          window.history.back()
        }
      })
    }

    await initApp()

    setInterval(() => {
      checkTokenValidity()
    }, 1800000)

    // Call F7 APIs here
  })
})

watch(isCoreAppLoaded, (newValue: boolean) => {
  if (!newValue) {
    f7.dialog.preloader('Načítavam aplikáciu...')
  } else {
    f7.dialog.close(undefined, false)
  }
})

// Add the declaration for __APP_VERSION__ to resolve the linter error.
// Make sure your build process injects the correct value.
declare const __APP_VERSION__: string
</script>
